/**
 * Main(-JS-File)
 */

import {getRootVar} from "../../shared/utils";
import vendors from './vendors/vendors';

// --------
// Let's go
// --------

document.addEventListener('DOMContentLoaded', function() {
	window.RootvarPrefix = getRootVar('global-rootvar-prefix');

	vendors();
});